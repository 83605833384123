const i18n = {
    "pt-BR": {
        translations: {
            Page: {
                title: "Checkout Propago" 
            },
            footer: {
                safeEnvironment: "Ambiente Seguro",
                poweredByPropago: "Powered by Propago",
                sslCertificate: "Certificado SSL",
            },
            header: {
                frequency: "Periodicidade",
                data: "Dados",
                amount: "Valor",
                method: "Método",
                payment: "Pagamento",
            },
            buttons: {
                advance: "Avançar",
                back: "Voltar",
                makeDonation: "Realizar doação",
                change: "Alterar",
                copyCode: "Copiar código",
                copied: "Copiado!",
                finish: "Finalizar",
                backToMain: "Voltar para o início",
                changePaymentMethod: "Escolher outra forma de pagamento",
            },
            tabFrequency: {
                title: "Vamos lá!",
                subTitle: "Por quanto tempo deseja ajudar?",
                allMonths: "Todos os meses",
                onlyOneTime: "Uma vez só",
                allMonthDescription:
                "Estipule um valor que não pese no seu orçamento e contribua todos os meses. A cobrança é automática, não dá trabalho e ajuda muito a nossa entidade.",
                onlyOneTimeDescription:
                "Doações pontuais também são importantes, porque ajudam a pagar eventuais emergências. Mas não esqueça, sobrou um dinheirinho, volte para ajudar.",
            },
            tabData: {
                title: "Quem está ajudando?",
                description:
                "Agora você pode doar como Empresa (CNPJ)<br/>ou Pessoa Física (CPF)",
                subTitle: "Preencha seus dados abaixo:",
                fullyName: "Nome/Razão Social:",
                name: "Nome",
                corporateName: "Razão Social",
                email: "E-mail:",
                emailPlaceholder: "email@domínio.com",
                checkEmail: "Confirmar:",
                checkEmailPlaceholder: "Confirme seu e-mail",
                cpfCnpj: "CPF/CNPJ",
                cpfCnpjPlaceholder: "Preencha apenas com números",
                cnpj: "CNPJ",
                phoneNumber: "Telefone (WhatsApp):",
                phoneNumberPlaceholder: "Preencha apenas com números",
                birthday: "Data de nascimento:",
                birthdayPlaceholder: "DD/MM/AAAA",
                cpf: "CPF:",
                state: "Estado:",
                city: "Cidade:",
                cep: "CEP:",
                cepPlaceholder: "Preencha apenas com números",
                address: "Endereço:",
                addressNumber: "Nº:",
                complement: "Complemento:\n(opcional)",
                dontKnowCep: "não sei o cep",
                checkbox:
                " Autorizo {{name}} a manter meus dados cadastrais conforme LGPD 13.709/2018*",
                checkboxDescription: "*Autorização deste campo é obrigatória.",
            },
            tabAmount: {
                title: "Qual o valor da doação?",
                subTitle: "Selecione uma opção ou preencha um valor desejado:",
                differentAmount: "Quero doar um valor diferente:",
                errorMinimumValue: "O valor deve ser maior que",
            },
            tabMethod: {
                title: "Como você deseja pagar?",
                subTitle: "Selecione uma opção desejada:",
                creditCard: "Cartão de crédito",
                pix: "Pix",
                bankBillet: "Boleto bancário",
            },
            tabPayment: {
                title: "Pagamento",
                subTitle: "Confira as informações abaixo e realize o pagamento:",
                titleMobile: "Confirmação",
                subTitleMobile: "Confira as informações abaixo:",
                frequency: "Periodicidade:",
                donateAmount: "Valor da doação:",
                paymentType: "Tipo de Pagamento:",
                allRight: "Tudo Certo!",
                payment: "Pagamento",
                fillPaymentInformation: "Preencha as informações de pagamento:",
                cardNumber: "Número do Cartão",
                namePrintedCard: "Nome impresso no Cartão",
                expirationMonthYear: "Mês e ano de validade:",
                placeholderExpiration: "Somente números",
                cvv: "CVC",
                placeholderCvv: "Somente números",
                billetPayment: "Pagamento Boleto",
                billetPaymentDescription:
                "Para realizar o pagamento, copie e cole o código de barras no seu aplicativo do banco:",
                pixPayment: "Pagamento PIX",
                pixPaymentDescription:
                "Para realizar o pagamento, escaneie o QR Code abaixo ou copie e cola a chave pix no seu aplicativo do banco:",
                copyBilletCode:
                "Copie e cole o código de barras no seu aplicativo do banco:",
                sendByEmail: "Enviar por e-mail",
                print: "Imprimir",
                pixTitle:
                "Para realizar o pagamento, escaneie o QR Code ao lado ou copie e cola a chave pix no seu aplicativo do banco:",
            },
            spanBlur: {
                wait: "Aguarde...",
                processingData: "Processando os dados enviados.",
                loading: "Carregando os dados",
                sentPaymentInformations: "Enviando informações de pagamento.",
                sentInformationsEmail: "Enviando as informações para o seu e-mail.",
                success: "Sucesso!",
                successDescription:
                "Você receberá um e-mail com todas as informações sobre a sua doação.",
                successDescriptionBottom:
                "Verifique sua caixa de SPAM caso não tenha recebido.",
                ops: "Ops...",
                opsDescription: "Algo não deu certo com a sua doação.",
                opsDescriptionBottom:
                "Mas fique tranquilo. Você pode optar por outro tipo de pagamento.",
                opsDescriptionCustomer: "Algo não deu certo com as suas informações.",
            },
        }
    },
    "en": {
        translations: {
            Page: {
                title: "Propago's Checkout" 
            },
            footer: {
                safeEnvironment: "Safe Environment",
                poweredByPropago: "Powered by Propago",
                sslCertificate: "SSL Certificate",
            },
            header: {
                frequency: "Frequency",
                data: "Data",
                amount: "Amount",
                method: "Method",
                payment: "Payment",
            },
            buttons: {
                advance: "Advance",
                back: "Back",
                makeDonate: "Donate",
                change: "Change",
                copyCode: "Copy code",
                copied: "Copied!",
                finish: "Finish",
                backToMain: "Back to Main",
                changePaymentMethod: "Change payment method",
            },
            tabFrequency: {
                title: "Let's go!",
                subTitle: "Would you like to support us monthly or just once?",
                allMonths: "Monthly",
                onlyOneTime: "Only once",
                allMonthDescription:
                  "Choose an amount that doesn't compromise your budget. This amount will be automatically charged from your credit card every month.",
                onlyOneTimeDescription:
                  "Punctual donations are also very welcome, since they can cover emergency debts. Whenever you have some extra money, come back here to help us.",
            },
            tabData: {
                title: "Who's helping?",
                subTitle: "Fulfill with your information:",
                fullyName: "Full Name:",
                name: "Name:",
                email: "E-mail:",
                emailPlaceholder: "email@domain.com",
                checkEmail: "Confirm:",
                checkEmailPlaceholder: "Confirm your e-mail",
                phoneNumber: "Phone Number:",
                phoneNumberPlaceholder: "Fill in numbers only",
                birthday: "Birthday:",
                birthdayPlaceholder: "MM-DD-YYYY",
                cpf: "Security Number:",
                state: "State:",
                city: "City:",
                cep: "ZIP Code:",
                cepPlaceholder: "Fill in numbers only",
                address: "Address:",
                addressNumber: "Nº:",
                complement: "Complement(opcional)",
                dontKnowCep: "I don't know the ZIP Code",
                checkbox:
                  " I authorize {{name}} to keep my registration data according to GPDL (LGPD Brazil) 13.709/2018*",
                checkboxDescription: "*Authorization of this field is mandatory",
            },
            tabAmount: {
                title: "What's the amount?",
                subTitle: "Choose an option or inform any other amount:",
                differentAmount: "I want a different amount:",
                errorMinimumValue: "The minimum value should be higher than",
                estimatedValue: "Estimated Value:",
            },
            tabMethod: {
                title: "Payment method?",
                subTitle: "Select option:",
                creditCard: "Credit card",
            },
            tabPayment: {
                title: "Payment",
                subTitle: "Check all the information bellow abe finish the payment:",
                titleMobile: "Confirmation",
                subTitleMobile: "Check the information bellow:",
                frequency: "Frequency:",
                donateAmount: "Amount:",
                paymentType: "Payment Method:",
                allRight: "All right!",
                payment: "Payment",
                estimatedValue: "Estimated Value:",
                fillPaymentInformation: "Insert the payment information:",
                cardNumber: "Credit Card number",
                namePrintedCard: "Name printed on the card",
                expirationMonthYear: "Expiration month and year:",
                placeholderExpiration: "Fill in numbers only",
                cvv: "CVV",
                placeholderCvv: "Fill in numbers only",
            },
            spanBlur: {
                wait: "Wait...",
                processingData: "Processing your data.",
                loading: "Loading the data",
                sentPaymentInformations: "Sending payment information.",
                sentInformationsEmail: "Sending information to your e-mail.",
                success: "Success!",
                successDescription:
                  "You will receive an e-mail with all the information about your donation.",
                successDescriptionBottom: "Check your SPAM in case you didn't receive it.",
                ops: "Oops...",
                opsDescription: "Something is wrong with your donation.",
                opsDescriptionBottom:
                  "But it's ok. You can choose another credit card or you can try it later.",
                opsDescriptionCustomer: "Something is wrong with your information."
            },
        }
    },
    "es": {
        translations: {
            Page: {
                title: "Checkout de Propago" 
            },
            footer: {
                safeEnvironment: "Ambiente Seguro",
                poweredByPropago: "Powered by Propago",
                sslCertificate: "Certificado SSL",
            },
            header: {
                frequency: "Frecuencia",
                data: "Dado",
                amount: "Valor",
                method: "Método",
                payment: "Pago",
            },
            buttons: {
                advance: "Avanzar",
                back: "Volver",
                makeDonate: "Donar",
                change: "Cambiar",
                copyCode: "Copiar codigo",
                copied: "Copiado!",
                finish: "Finalizar",
                backToMain: "Hacia arriba",
                changePaymentMethod: "Escoger otra forma de pago",
            },
            tabFrequency: {
                title: "¡Vamos!",
                subTitle: "¿Cual la frecuencia?",
                allMonths: "Todos los meses",
                onlyOneTime: "Sólo una vez",
                allMonthDescription:
                    "Estipula una cantidad que no pese en su presupuesto y aporta todos los meses. El cobro es automático, no te da trabajo y ayuda mucho a nuestra entidad.",
                onlyOneTimeDescription:
                    "Las donaciones puntuales también son importantes porque ayudan a pagar emergencias. Pero no te olvidas, si queda un poco de dinero, vuelva para ayudar.",
            },
            tabData: {
                title: "Quien esta ayudando?",
                subTitle: "Rellena tus datos abajo:",
                fullyName: "Nombre Completo:",
                name: "Nombre:",
                email: "E-mail:",
                emailPlaceholder: "email@dominio.com",
                checkEmail: "Confirmar:",
                checkEmailPlaceholder: "Confirme su e-mail",
                phoneNumber: "Teléfono (WhatsApp):",
                phoneNumberPlaceholder: "Rellene solo números",
                birthday: "Fecha de nacimiento:",
                birthdayPlaceholder: "DD-MM-AAAA",
                cpf: "Número de seguridad social:",
                state: "País:",
                city: "Ciudad:",
                cep: "Código postal:",
                cepPlaceholder: "Rellene solo números",
                address: "Dirección:",
                addressNumber: "Nº:",
                complement: "Complemento:\n(opcional)",
                dontKnowCep: "No sé el código postal",
                checkbox:
                    " Autorizo {{name}} a conservar mis datos de registro según GPDL (LGPD Brasil) 13.709/2018*",
                checkboxDescription: "*La autorizacion de este campo es obligatoria.",
            },
            tabAmount: {
                title: "Cual es el valor de la donación?",
                subTitle: "Seleccione una opción o complete un valor deseado:",
                differentAmount: "Quiero donar una cantidad diferente:",
                errorMinimumValue: "El valor debe ser mayor que",
                estimatedValue: "Valor Estimado:",
            },
            tabMethod: {
                title: "Método de pago",
                subTitle: "Seleccione una opción deseada:",
                creditCard: "Tarjeta de crédito",
                payment: "Pago",
                fillPaymentInformation: "Complete la información de pago:",
                cardNumber: "Número de la tarjeta",
                namePrintedCard: "Nombre impreso en la tarjeta:",
                expirationMonthYear: "Mes y año de caducidad:",
                cvv: "CVV",
            },
            tabPayment: {
                title: "Pago",
                subTitle:
                    "Verifique toda la información a continuación para finalizar el pago:",
                titleMobile: "Confirmación",
                subTitleMobile: "Revisa la información a continuación:",
                frequency: "Frecuencia:",
                donateAmount: "Monto:",
                paymentType: "Método de pago:",
                allRight: "¡Está bien!",
                payment: "Pago",
                estimatedValue: "Valor estimado:",
                fillPaymentInformation: "Inserte la información de pago:",
                cardNumber: "Número de Tarjeta de Crédito",
                namePrintedCard: "Nombre impreso en la tarjeta",
                expirationMonthYear: "Mes y año de caducidad:",
                placeholderExpiration: "Rellene solo números",
                cvv: "CVV",
                placeholderCvv: "Rellene solo números",
            },
            spanBlur: {
                wait: "Espera...",
                processingData: "Tratamiento de los datos enviados.",
                loading: "Carga de datos",
                sentPaymentInformations: "Envío de información de pago.",
                sentInformationsEmail: "Envío de información a su correo electrónico.",
                success: "Éxito",
                successDescription:
                    "Recibirás un correo electrónico con toda la información sobre tu donación.",
                successDescriptionBottom:
                    "Revisa tu carpeta de SPAM si no lo has recibido.",
                ops: "Oops...",
                opsDescription: "Algo salió mal con tu donación.",
                opsDescriptionBottom:
                    "Pero no te preocupes. Puedes elegir otra tarjeta de crédito.",
                opsDescriptionCustomer: "Algo salió mal con su información.",
            },
        }
    }
};
  
export default i18n;