import { Suspense, lazy, useCallback } from "react";

// Styles
import { WrapperSpanBlur } from "./styles";

// Lazy Loaded Components
const Loading = lazy(() => import("../Warnings/LoadingData"));
const WaitPayment = lazy(() => import("../Warnings/WaitPayment"));
const WaitEmail = lazy(() => import("../Warnings/WaitEmail"));
const FailedCustomer = lazy(() => import("../Warnings/FailedCustomer"));
const FailedDonation = lazy(() => import("../Warnings/FailedDonation"));
const SuccessfulDonation = lazy(() => import("../Warnings/SuccessfulDonation"));

const withSpan = (type, functions) => {
  switch (type) {
    case "LOADING":
    case "LOADING_DATA":
      return <Loading />;
    case "WAIT_PAYMENT":
      return <WaitPayment />;
    case "WAIT_EMAIL":
      return <WaitEmail />;
    case "FAILED_CUSTOMER": {
      const { onPressBackMethod } = functions;
      return <FailedCustomer onPressBackMethod={onPressBackMethod} />;
    }
    case "FAILED_DONATION": {
      const { onPressOtherPaymentMethod } = functions;
      return (
        <FailedDonation onPressOtherPaymentMethod={onPressOtherPaymentMethod} />
      );
    }
    case "SUCCESSFUL_DONATION": {
      const { onBackToMainPress } = functions;
      return <SuccessfulDonation onBackToMainPress={onBackToMainPress} />;
    }
    default:
      return console.error(`${type} span type does not exist`);
  }
};

const SpanBlur = ({
  spanBlurState,
  changeTab,
  checkoutData,
  setSpanVisibility,
}) => {
  const onBackToMainPress = useCallback(() => {
    setSpanVisibility(false);
    changeTab("AMOUNT");
  }, []);

  const onPressOtherPaymentMethod = useCallback(() => {
    setSpanVisibility(false);
    changeTab(3);
  }, []);

  const onPressBackMethod = useCallback(() => {
    setSpanVisibility(false);
  }, []);

  if (spanBlurState === "SUCCESSFUL_DONATION") {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "pageview-sucesso-doacao",
        valorDoacao: checkoutData.amount,
        eventProps: {
          category: "pageview-sucesso-doacao",
          action: "pageview",
        },
      });
    }
  }

  const blurryContent = withSpan(spanBlurState, {
    onBackToMainPress,
    onPressOtherPaymentMethod,
    onPressBackMethod,
  });

  return (
    <WrapperSpanBlur className="position-absolute d-flex align-items-center justify-content-center z-3 w-100 h-100 span-blur">
      <Suspense>{blurryContent}</Suspense>
    </WrapperSpanBlur>
  );
};

export default SpanBlur;
