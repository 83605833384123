// NAME
export const validName = (text = "") => {
    if (text.trim() === "") {
        return false;
    } else {
        return true;
    }
};

// EMAIL
export const validEmail = (email) => {
    if (!email) {
        return false;
    }
    const pattern =
        // eslint-disable-next-line no-control-regex
        /(^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$)/;

    return email?.match(pattern) !== null;
};

// Documents
export const cpfMask = (value) =>
    value
        ?.replace(/\D/g, "")
        .substr(0, 11)
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");

export const cpfCnpjMask = (value) => {
    const nums = `${value}`.replace(/\D+/g, "");

    if (`${nums}`.length > 11)
        return value
            .replace(/\D+/g, "")
            .replace(/(\d{2})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1/$2")
            .replace(/(\d{4})(\d)/, "$1-$2")
            .replace(/(-\d{2})\d+?$/, "$1");
    else
        return value
            ?.replace(/\D/g, "")
            .substr(0, 11)
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
};

export const validCpf = (strCPF) => {
    if (!strCPF) {
        return false;
    }
    strCPF = strCPF.replace(/\.|-/g, "");
    let soma;
    let resto;
    soma = 0;
    if (
        [
            "11111111111",
            "22222222222",
            "33333333333",
            "44444444444",
            "55555555555",
            "66666666666",
            "77777777777",
            "88888888888",
            "99999999999",
            "00000000000",
        ].includes(strCPF)
    ) {
        return false;
    }
    let i = 0;
    for (i = 1; i <= 9; i++) {
        soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
        resto = 0;
    }
    if (resto !== parseInt(strCPF.substring(9, 10))) {
        return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) {
        soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
        resto = 0;
    }
    if (resto !== parseInt(strCPF.substring(10, 11))) {
        return false;
    }

    return true;
};

export const validCnpj = (value) => {
    // Aceita receber o valor como string, número ou array com todos os dígitos
    const validTypes =
        typeof value === "string" ||
        Number.isInteger(value) ||
        Array.isArray(value);

    // Elimina valor em formato inválido
    if (!validTypes) return false;

    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false;

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)];
    if (items.length === 1) return false;

    // Cálculo validador
    const calc = (x) => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;

        for (let i = x; i >= 1; i--) {
            const n = slice[x - i];
            sum += n * factor--;
            if (factor < 2) factor = 9;
        }

        const result = 11 - (sum % 11);

        return result > 9 ? 0 : result;
    };

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12);

    // Valida 1o. dígito verificador
    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    // Valida 2o. dígito verificador
    const digit1 = calc(13);

    return digit1 === digits[1];
};

export const getInvalidDonorData = (data, isPTBR) => {
    let invalidDataList = []; 

    if(!validName(data.donorName)){
      invalidDataList.push("NAME")
    }

    if(!validEmail(data.donorEmail)){
      invalidDataList.push("EMAIL");
    }

    if(!validEmail(data.donorCheckEmail) || data.donorCheckEmail !== data.donorEmail){
      invalidDataList.push("CHECK_EMAIL")
    }

    if(isPTBR && !validCpf(data.donorDocument) && !validCnpj(data.donorDocument)){
      invalidDataList.push("DOCUMENT")
    }

    if(!data.donorConsent){
      invalidDataList.push("CONSENT")
    }

    return invalidDataList;
}


// Amount
const numberToBRL = (numero) => {
    numero = numero.toFixed(2).split(".");
    numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
};

export const numberToDollar = (numero) => {
    const result = new Intl.NumberFormat("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(numero);
    return "US$ " + result;
};

export const numberToCurrency = (numero) => {
    const result = new Intl.NumberFormat("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(numero);
    return result;
};

export const removeAmountMask = (amountDigited) => {
    return parseInt(amountDigited.replace(/[^0-9]/g, "")) / 100;
};

export const amountMask = (amountDigited) => {
    const amount =
        typeof amountDigited === "string"
            ? removeAmountMask(amountDigited)
            : amountDigited;

    return `${numberToBRL(amount)}`;
};

// Card Number
export const cardNumberMask = (value) => {
    return value
        ?.replace(/\D/g, "")
        .substr(0, 16)
        .replace(/(\d{4})(\d)/, "$1 $2")
        .replace(/(\d{4})(\d)/, "$1 $2")
        .replace(/(\d{4})(\d)/, "$1 $2")
        .replace(/(\d{4})(\d{1,4})$/, "$1 $2");
};

// Card Month Year
export const cardMonthYearMask = (value) => {
    return value
        ?.replace(/\D/g, "")
        .substr(0, 6)
        .replace(/(\d{2})(\d{1,4})$/, "$1 / $2");
};

// Card CVV
export const cardCvvMask = (value) => {
    return value?.replace(/\D/g, "").substr(0, 4);
};

// Billet
export const billetMask = (value) => {
    if (value) {
        return value[0] !== "8"
            ? value
                .replace(/\D/g, "")
                .replace(/(\d{5})(\d)/, "$1.$2")
                .replace(/(\d{5})(\d)/, "$1\n$2")
                .replace(/(\d{5})(\d)/, "$1.$2")
                .replace(/(\d{5})(\d)/, "$1\n$2")
                .replace(/(\d{5})(\d)/, "$1.$2")
                .replace(/(\d{5})(\d)/, "$1\n$2")
                .replace(/(\d{1})(\d{2})(\d{15})\d+?$/, "$1 $2 $3")
            : value
                .replace(/\D/g, "")
                .replace(/(\d{11})(\d)/, "$1-$2\n")
                .replace(/(\d{11})(\d)/, "$1-$2\n")
                .replace(/(\d{11})(\d)/, "$1-$2\n")
                .replace(/(\d{11})(\d)/, "$1-$2")
                .replace(/(\d{1})(\d{2})(\d{15})\d+?$/, "\n$1 $2 $3");
    }
};