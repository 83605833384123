export const setStyles = (config) => {
  document.documentElement.style.setProperty(
    "--header-bg-color",
    config["header-bg-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--content-bg-color",
    config["content-bg-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--footer-bg-color",
    config["footer-bg-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--error-color",
    config["error-color"] ?? '#FB2667'
  );
  document.documentElement.style.setProperty(
    "--input-error-color",
    config["input-error-color"] ?? '#FDBCBC'
  );
  document.documentElement.style.setProperty(
    "--finish-bg-color",
    config["finish-bg-color"] ?? '#0EC14A'
  );
  document.documentElement.style.setProperty(
    "--finish-color",
    config["finish-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--advance-bg-color",
    config["advance-bg-color"]
  );
  document.documentElement.style.setProperty(
    "--advance-color",
    config["advance-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--main-font-color",
    config["main-font-color"] ?? '#78849E'
  );
  document.documentElement.style.setProperty(
    "--main-color",
    config["main-color"]
  );
  document.documentElement.style.setProperty(
    "--line-bg-color",
    config["line-bg-color"] ?? '#E8E8E8'
  );
  document.documentElement.style.setProperty(
    "--card-font-color",
    config["card-font-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--form-button-border-color",
    config["form-button-border-color"] ?? '#BCE0FD'
  );
  document.documentElement.style.setProperty(
    "--frequency-bg-color",
    config["frequency-bg-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--frequency-bottom-bg-color",
    config["frequency-bottom-bg-color"] ?? '#BBC1CD'
  );
  document.documentElement.style.setProperty(
    "--frequency-bottom-font-color",
    config["frequency-bottom-font-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--payment-change-mobile-font-color",
    config["payment-change-mobile-font-color"] ?? '#FFF'
  );
  document.documentElement.style.setProperty(
    "--card-icon-color",
    config["card-icon-color"] ?? '#2699FB'
  );
  document.documentElement.style.setProperty(
    "--card-icon-visa-electron",
    config["card-icon-visa-electron"] ?? '#2699FB'
  );
  document.documentElement.style.setProperty(
    "--card-icon-master-card-color-1",
    config["card-icon-master-card-color-1"] ?? '#2E88F3'
  );
  document.documentElement.style.setProperty(
    "--card-icon-master-card-color-2",
    config["card-icon-master-card-color-2"] ?? '#2699FB'
  );
  document.documentElement.style.setProperty(
    "--card-icon-master-card-color-3",
    config["card-icon-master-card-color-3"] ?? '#4FADFE'
  );
  document.documentElement.style.setProperty(
    "--card-icon-elo",
    config["card-icon-elo"] ?? '#2699FB'
  );
  document.documentElement.style.setProperty(
    "--card-icon-visa",
    config["card-icon-visa"] ?? '#2699FB'
  );
  document.documentElement.style.setProperty(
    "--card-icon-amex-color-1",
    config["card-icon-amex-color-1"] ?? '#2699FB'
  );
  document.documentElement.style.setProperty(
    "--card-icon-amex-color-2",
    config["card-icon-amex-color-2"] ?? '#FFF'
  );
};

export const setStylesFromCheckoutPropagoDomain = () => {
  const html = document.querySelector('html');
  html.style.setProperty("width", "100%");
  html.style.setProperty("height", "100%");
  
  const body = document.querySelector('body');
  body.style.setProperty("margin", "0");
  body.style.setProperty("-webkit-font-smoothing", "antialiased");
  body.style.setProperty("-moz-osx-font-smoothing", "grayscale");
  body.style.setProperty("display", "flex");
  body.style.setProperty("width", "100%");
  body.style.setProperty("height", "100%");
}