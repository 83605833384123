import styled from 'styled-components'

export const WrapperSpanBlur = styled.div`
  backdrop-filter: blur(30px);

  .error > svg {
    fill: var(--error-color);
  }

  .success > svg {
    fill: var(--finish-bg-color);
  }

  .response__title {
    color: var(--main-font-color);
    font-size: 35px;
    font-weight: bold;
    text-align: center;
  }

  .response__subtitle {
    padding: 25px 15px 0;
    color: var(--main-font-color);
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }

  .response__bottom-description {
    padding: 10px 15px 0;
    color: var(--main-font-color);
    font-size: 17px;
    font-weight: 500;
    text-align: center;
  }

  .response__button {
    padding: 20px 25px;
  }

  .response__button svg {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
`