import { Row } from "react-bootstrap";

// Components
import Checkout from "./components/CheckoutContainer";

// Styles
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = ({ checkoutID }) => (
  <div className="position-relative w-100" id="section-checkout">
    <Row className="justify-content-center g-0 row-checkout">
      <Checkout checkoutID={checkoutID} />
    </Row>
  </div>
);

export default App;