import styled from "styled-components";

export const WrapperFooter = styled.div`
  background-color: var(--footer-bg-color);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  color: var(--main-font-color);
  font-size: 12px;

  .footer-left {
    padding-left: 25px;
  }

  .footer-right {
    padding-right: 25px;
  }

  .footer-text-left {
    margin-left: 6px;
    margin-top: 7px;
  }

  .footer-text-right {
    margin-left: 6px;
  }

  svg {
    height: 37px;
    fill: var(--main-color);
  }

  @media (max-width: 576px) {
    font-size: 9px;

    img {
      height: 20px;
    }

    .footer-text-left,
    .footer-text-right {
      display: none;
    }
  }
`;
