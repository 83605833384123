// Components
import { useTranslation } from "react-i18next";

// Styles
import { Wrapper, WrapperTitle, RowContainer, Column, WrapperDescription, WrapperText } from "./styles";

const Wireframe = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <WrapperTitle>{t("COMP_ALLYA.title")}</WrapperTitle>
            <WrapperDescription dangerouslySetInnerHTML={{ __html: t("COMP_ALLYA.description") }}></WrapperDescription>
            <RowContainer>
                <Column>
                    <img src="/assets/ALLYA.webp" width={200} height={200} alt='' loading="lazy"/>
                </Column>
                <Column>
                    <WrapperText dangerouslySetInnerHTML={{ __html: t("COMP_ALLYA.text") }}></WrapperText>
                </Column>
            </RowContainer>
        </Wrapper>
    );
}

export default Wireframe; 