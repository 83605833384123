import { useTranslation } from "react-i18next";

// Components
import Button from "../Button";

// Assets
import { ReactComponent as HeartButton } from "../../assets/svgs/heartButton.svg";

// Styles
import { WrapperButtonFloating, WrapperButtonFloatingBottom } from "./styles";

const ButtonDonateNow = ({ primaryColor, secondaryColor, borderFixedColor, borderFixedHoverColor }) => {
  const { t } = useTranslation();

  return (
    <>
      <WrapperButtonFloating className="d-none d-md-block">
        <Button
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          borderFixedColor={borderFixedColor}
          borderFixedHoverColor={borderFixedHoverColor}
        >
          {t("ButtonFloating.button")}
        </Button>
      </WrapperButtonFloating>
      <WrapperButtonFloatingBottom
        className="d-none"
      >
        <HeartButton />
        {t("ButtonFloating.buttonBottom")}
      </WrapperButtonFloatingBottom>
    </>
  );
};

export default ButtonDonateNow;