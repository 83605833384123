import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-top: 50px;
  padding-bottom: 75px;

  .row-accordion {
    margin-top: 50px;
  }

  .accordion-item {
    background-color: transparent;
    border: 0;
    margin-bottom: 15px;
  }
  .accordion-button {
    background-color: transparent;
    box-shadow: none;
    background-color: ${({ primaryColor }) => primaryColor};
    border: 1px solid ${({ primaryColor }) => primaryColor};
    border-radius: 5px !important;
    padding: 15px 20px 15px 20px !important;
  }
  .accordion-button.collapsed {
    background-color: transparent;
    border: 1px solid ${({ primaryColor }) => primaryColor};
    border-radius: 5px !important;
    padding: 15px 20px 15px 20px !important;

    .accordion-header .number {
      color: ${({ primaryColor }) => primaryColor};
    }
    .accordion-header .bar {
      background-color: ${({ primaryColor }) => primaryColor};
    }
    .accordion-header .description {
      color: ${({ primaryColor }) => primaryColor};
    }
  }
  .accordion-header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .number {
      font-size: 20px;
      font-weight: 600;
      color: ${({ secondaryColor }) => secondaryColor};
    }
    .bar {
      width: 1px;
      height: 17px;
      background-color: ${({ secondaryColor }) => secondaryColor};
      margin-right: 15px;
      margin-left: 15px;
    }
    .description {
      font-size: 15px;
      font-weight: ${({ textRegular }) => textRegular};
      color: ${({ secondaryColor }) => secondaryColor};
    }
  }

  .accordion-body {
    color: ${({ primaryColor }) => primaryColor};
    font-size: 15px;
    font-weight: ${({ textRegular }) => textRegular};
    line-height: 24px;
    margin-top: 15px;
    a {
      color: ${({ primaryColor }) => primaryColor};
    }
  }

  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg id='PLUS_-_POSITIVO' data-name='PLUS - POSITIVO' xmlns='http://www.w3.org/2000/svg' width='21.002' height='21.002' viewBox='0 0 14.002 14  .002'%3E%3Cpath id='União_42' data-name='União 42' d='M13904,11728v-7h-7v-2h7v-7h2v7h7v2h-7v7Z' transform='translate(-13896.999 -11711.999)' 
      fill='${({ primaryColor }) => `${primaryColor}`.replace("#", "%23")}'/%3E%3C/svg%3E%0A");
    width: 16px !important;
    height: 16px !important;
  }
`;

export const WrapperText = styled.div`
  ${({ titleColor, textRegular }) =>
    titleColor
      ? `
    font-size: 35px;
    line-height: 40px;
    color: ${titleColor};
    text-align: center;
    font-weight: ${textRegular};
    
    strong {
      font-weight: 600;
    }

    @media ${devices.xs} {
      font-size: 30px;
    }
  `
      : ""}
`;
