// Components
import Translator from "../Translator";

// Assets
import { ReactComponent as SafeEnvironmentIcon } from "../../assets/svgs/safeEnvironment.svg";
import { ReactComponent as SslCertificate } from "../../assets/svgs/sslCertificate.svg";

// Styles
import { WrapperFooter } from "./styles";

function Footer() {
  return (
    <WrapperFooter>
      <div className="footer-left d-flex align-items-center">
        <SafeEnvironmentIcon />
        <div className="footer-text-left">
          <Translator path="footer.safeEnvironment" />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Translator path="footer.poweredByPropago" />
      </div>
      <div className="footer-right d-flex align-items-center">
        <SslCertificate />
        <div className="footer-text-right">
          <Translator path="footer.sslCertificate" />
        </div>
      </div>
    </WrapperFooter>
  );
}

export default Footer;
