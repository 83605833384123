import { useTranslation } from "react-i18next";

// Translations
import { isPtBr } from "../../../../i18n"; // when it is a submodule 'i18n/' folder is in the main app

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, createRef, Suspense, lazy } from "react";

// Libs
import Recaptcha from "react-google-invisible-recaptcha";

// Internal Libs
import { getConfig } from "../../api";
import { setStyles, setStylesFromCheckoutPropagoDomain } from "../../utils/helperStyles";

// Components
import Footer from "../Footer";
import SpanBlur from "../SpanBlur";
import Header from "../Header";
import Loading from "../Warnings/LoadingData";

// Tabs 
const TabFrequency = lazy(() => import("../Tabs/Frequency"));
const TabFormData = lazy(() => import("../Tabs/FormData"));
const TabAmount = lazy(() => import("../Tabs/Amount"));
const TabMethod = lazy(() => import("../Tabs/Method"));
const TabPayment = lazy(() => import("../Tabs/Payment"));

const Checkout = ({ checkoutID }) => {
  const { i18n } = useTranslation(); 

  const recaptchaRef = createRef();

  const TABS = [
    { name: "FREQUENCY", component: TabFrequency },
    { name: "DATA", component: TabFormData },
    { name: "AMOUNT", component: TabAmount },
    { name: "METHOD", component: TabMethod },
    { name: "PAYMENT", component: TabPayment },
  ]

  // States
  const [tabIndex, setTabIndex] = useState(0);
  const [spanBlurState, setSpanBlurState] = useState("LOADING");
  const [apiData, setApiData] = useState({
    key: "",
    config: {},
  });
  
  // initial state for checkout data
  const [checkoutData, setCheckoutData] = useState({
    frequency: "", 
    paymentMethod: "",
    donorId: 0,
    donorName: "",
    donorEmail: "",
    donorCheckEmail: "",
    donorDocument: "",
    amount: 0,
    paymentResponse: {},
  });

  // Props Objects  
  const activeTabProps = {
    // Tab
    changeTab: (value) => typeof value === 'string' ? setTabIndex(TABS.findIndex((item) => item.name == value.toUpperCase())) : setTabIndex(value),
    // Span Blur
    spanBlurState,
    setSpanVisibility: (active, type = null) => active ? setSpanBlurState(type) : setSpanBlurState(false),
    // API
    apiData,
    // Checkout
    checkoutData,
    updateCheckout: (data) => setCheckoutData({ ...checkoutData, ...data }),
    recaptchaRef,
  };

  const headerProps = {
    apiData,
    tabIndex, 
    tabNames: TABS.map((tab) => tab.name),
    checkoutData,
    changeTab: (value) => typeof value === 'string' ? setTabIndex(TABS.findIndex((item) => item.name == value.toUpperCase())) : setTabIndex(value),
    isPTBR: isPtBr(i18n.language),
  }

  // Active Tab
  const ActiveTab = TABS[tabIndex].component;

  const onMount = async () => {
    try {
      let API_KEY = checkoutID;

      if (!API_KEY) {
        API_KEY = document.getElementById("checkoutID").value;
      }

      const result = await getConfig(API_KEY);
      let config = result.data.additional_data;

      if (typeof config === 'string') {
        try {
          config = JSON.parse(config)
        } catch (error) {
          //
        }
      }

      const dollar = result.currency.USDBRL.bid;
      setStyles(config);
      
      setApiData({
        key: API_KEY,
        config,
        dollarValue: dollar,
      });

      if (API_KEY && config) setSpanBlurState(false);

      if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "checkout.propagosocial.com.br"
      ) {
        setStylesFromCheckoutPropagoDomain();
      }
    } catch (error) {
      console.error('ERRO: ',error);
    }
  }

  useEffect(() => {
    onMount()
  }, []);

  return (
    <div className="app">
      <Recaptcha
        ref={recaptchaRef}
        sitekey="6Lc8V5UlAAAAADCbCx1apuMBowM4NLF5WyLX0Hte"
      />
      <div className="app-header">
        <Header {...headerProps } />
      </div>
      <div className="app-content">
        <Suspense fallback={<Loading type="LOADING" />}>
          <ActiveTab {...activeTabProps} />
        </Suspense>
      </div>
      <div className="app-footer">
        <Footer />
      </div>

      {spanBlurState && <SpanBlur {...activeTabProps} />}
    </div>
  );
};

export default Checkout;