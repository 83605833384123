import styled from 'styled-components'
import devices from '../../utils/helperGrids'

export const Wrapper = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 24px;
  padding-left: 24px;
  border-radius: 5px;
  font-size: 20px;
  border: 2px solid ${({primaryColor}) => primaryColor};
  background-color: ${({primaryColor}) => primaryColor};
  color: ${({secondaryColor}) => secondaryColor};
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  transition: 0.4s;
  :hover {
    background-color: ${({secondaryColor}) => secondaryColor};
    color: ${({primaryColor}) => primaryColor};
  }

  @media ${devices.xs} {
    font-size: 16px;
  }

  @media ${devices.sm} {
    font-size: 18px;
  }
`
