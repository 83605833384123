// Components
import Translator from "../Translator";

// Assets
import { ReactComponent as ArrowLeft } from "../../assets/svgs/arrowLeftHeader.svg";
import { ReactComponent as ArrowRight } from "../../assets/svgs/arrowRightHeader.svg";

// Styles
import { WrapperHeader } from "./styles";
import { getInvalidDonorData } from "../../utils/forms";

const DotsRow = ({tabNames, currentTab, permissions, changeTab}) => {
  return tabNames.map((tabName, index) => (
    <div 
      className={`header__card-container flex-column ${index === currentTab ? "d-flex" : "d-none d-sm-flex"}`} 
      key={'dot_'+tabName.toLowerCase()}
    >
      {(index === 0) && (
        <div className="header__card-fill header__card-fill--left"></div>
      )}
      {(index === tabNames.length-1) && (
        <div className="header__card-fill header__card-fill--right"></div>
      )}
        
      <div className="header__card">
        <div className={`d-flex justify-content-center align-items-center header__external-dot 
          ${index === currentTab ? "header__external-dot--active" : ""} 
        `}>
          <div className="d-flex justify-content-center align-items-center header__internal-dot"></div>
        </div>
        <div
          className={`header__title ${permissions[index] ? "header__title-develop" : ""}`}
          onClick={() => {
            if (permissions[index]) {
              changeTab(tabName);
            }
          }}
        >
          <Translator path={`header.${tabName.toLowerCase()}`} />
        </div>
      </div>
    </div> 
  ))
}

const Header = ({ apiData, tabIndex, tabNames, checkoutData, changeTab, isPTBR }) => {
  
  const isAmountTabClickable = (getInvalidDonorData(checkoutData, isPTBR) == false);
  const isMethodTabClickable = (checkoutData.amount >= apiData.config.valorMinimo);

  const permissionsList = [true, checkoutData.frequency, isAmountTabClickable, isMethodTabClickable, checkoutData.paymentMethod];

  const isBackArrowClickable = ((tabIndex-1) > -1 && permissionsList[tabIndex-1] != false);
  const isForwardArrowClickable = ((tabIndex+1) < 5 && permissionsList[tabIndex+1] != false);  

  function handleArrowClick(augmenter) {
    changeTab(tabIndex+augmenter)
  }
  
  return (
    <WrapperHeader className="d-flex justify-content-center align-items-center w-100">
      <div className="d-flex justify-content-center w-100">
        <div className="position-relative d-flex justify-content-center justify-content-sm-between w-75">
          {window.innerWidth > 575 && <div className="header__line"></div> }

          {(window.innerWidth < 576) && (
            <div 
              className={`header__control-arrow ${!isBackArrowClickable ? 'header__control-arrow--inactive' : ''}`} 
              onClick={() => isBackArrowClickable && handleArrowClick(-1)}>
              <ArrowLeft/>
            </div>
          )}

          <DotsRow 
            tabNames={tabNames} 
            currentTab={tabIndex} 
            permissions={permissionsList} 
            changeTab={changeTab} 
          />
          {(window.innerWidth < 576) && (
            <div 
              className={`header__control-arrow ${!isForwardArrowClickable ? 'header__control-arrow--inactive' : '' }`}
              onClick={() => isForwardArrowClickable && handleArrowClick(1)}
            >
              <ArrowRight/>                
            </div>
          )}
        </div>
      </div>
    </WrapperHeader>
  );
}

export default Header;
