// Assets
import { ReactComponent as HeartButton } from "../../assets/svgs/heartButton.svg";

// Utils
import { scrollTo } from "../../utils/helperScrolls";

// Styles
import { Wrapper } from './styles'

const Button = ({
  children,
  primaryColor,
  secondaryColor,
  borderFixedColor,
  borderFixedHoverColor,
  onClick = () => { scrollTo("section-checkout") },
  icon = true,
  buttonWidthVariable = false,
}) => {
  return (
    <Wrapper
      className="noselect"
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      borderFixedColor={borderFixedColor}
      borderFixedHoverColor={borderFixedHoverColor}
      onClick={onClick}
      buttonWidthVariable={buttonWidthVariable}
    >
      {icon && (<HeartButton />)}
      {children}
      {icon && (<HeartButton />)}
    </Wrapper>
  )
}

export default Button