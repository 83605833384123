import styled from "styled-components";

export const WrapperHeader = styled.div`
  height: 118px;
  background-color: var(--header-bg-color);
  font-size: 12px;
  color: var(--main-font-color);
  text-transform: uppercase;
  box-shadow: 0px 10px 18px rgba(69, 91, 99, 0.08);
  z-index: 1;

  .header__line {
    position: absolute;
    top: 22px;
    width: 100%;
    height: 2px;
    background-color: var(--line-bg-color);
    z-index: 1;
  }

  .header__control-arrow {
    position: relative;
    top: 11px;
    margin: 0 20px;
    cursor: pointer;
    z-index: 2;
  }

  .header__control-arrow--inactive {
    cursor: default;
  }

  .header__control-arrow svg {
    width: 24px;
    height: 24px;
    fill: var(--main-color);
  }

  .header__control-arrow--inactive svg {
    fill: var(--main-font-color)
  }

  .header__external-dot {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: transparent;
  }

  .header__external-dot--active {
    background-color: var(--main-color);
  }

  .header__external-dot--active .header__internal-dot {
    background-color: #FFF;
  }
  
  .header__internal-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--main-color);
  }

  .header__card-container {
    position: relative;
  }

  .header__card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;
  }

  .header__card-fill {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: var(--header-bg-color);
    z-index: 2;
  }

  .header__card-fill--left {
    left: 0;
  }

  .header__card-fill--right {
    right: 0;
  }

  .header__title-develop:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
