// Styles
import {
  Wrapper,
} from './styles'

const Button = ({children, primaryColor, secondaryColor, onClick = () => {}}) => {
  return (
    <Wrapper
      className="noselect"
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  )
}

export default Button