// Bootstrap 
import { Spinner } from "react-bootstrap";

// Components
import Translator from "../../Translator";

const Loading = ({ type }) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <Spinner animation="border" className="loading__spinner" />
            <p className="loading__title">
                <Translator path="spanBlur.wait" />
            </p>
            <p className="loading__subtitle">
                {type === "LOADING" ? <Translator path="spanBlur.loading"/> : <Translator path="spanBlur.processingData"/>}
            </p>
        </div>        
    )
}

export default Loading;